.form-control-borderless {
  border: none;
}

.form-control-borderless:hover,
.form-control-borderless:active,
.form-control-borderless:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.no-wrap {
  white-space: nowrap;
}

.btn-lg.no-wrap {
  display: inline-block;
  min-width: 80px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.input-height {
  height: 30px;
  padding: 5px;
}

.btn {
  height: auto;
}