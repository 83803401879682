.bootstrap_chat {
  background-color: #74EBD5;
  min-height: 100vh;
}

.text-small {
  font-size: 0.9rem;
}

.messages-box, .chat-box {
  height: 510px;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

