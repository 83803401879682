@keyframes spin {
	100% {
		transform: rotate(360deg);
 }
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	background-color: #fff;
}
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.spinner__circle {
	position: relative;
	width: 54px;
	height: 54px;
	overflow: hidden;
	background-color: #000;
	border-radius: 100%;
	animation: spin 1s infinite linear;
}
.spinner__circle-inner, .spinner__circle-gradient {
	position: absolute;
	width: 44px;
	height: 44px;
	transform: translate(-50%, -50%);
}
.spinner__circle-inner {
	top: 50%;
	left: 50%;
	background-color: #fff;
	border-radius: 100%;
}
.spinner__circle-gradient {
	top: -24px;
	left: 6px;
	background-color: #000;
	background-image: -moz-linear-gradient(0deg, #000 0%, #fff 50%);
	background-image: -webkit-linear-gradient(0deg, #000 0%, #fff 50%);
	background-image: linear-gradient(0deg, #000 0%, #fff 50%);
	transform: rotate(-45deg);
}
