button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: all .2s linear;
}

@media (prefers-reduced-motion:reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: #4f4f4f;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(18,102,241,.25);
}

.form-control {
  min-height: auto;
  padding-top: 4px;
  padding-bottom: 3.28px;
  transition: all .1s linear;
}

.form-control:focus {
  box-shadow: none;
  transition: all .1s linear;
  border-color: #1266f1;
  box-shadow: inset 0 0 0 1px #1266f1;
}

